.autofit-row.alert-autofit-row {
    padding: 0;
}
.alert-inline.alert {
    height: 44px;
    padding: 10px;
    margin: 0 1.5rem !important;
}
.alert-info {
    background: #e5f5fc;
    color: #017aad;
    border-color: #e5f5fc;
}
.alert-container{
    .alert-notifications-fixed{
        bottom: 4.25rem;
    }
}